const peopleRange = [
    { text: "全部", minCount: "", maxCount: "" },
    { text: "10人以下", minCount: 0, maxCount: 10 },
    { text: "10-30人", minCount: 10, maxCount: 30 },
    { text: "30-60人", minCount: 30, maxCount: 60 },
    { text: "60-100人", minCount: 60, maxCount: 100 },
    { text: "100-200人", minCount: 100, maxCount: 200 },
    { text: "200以人", minCount: 200, maxCount: "" },
  ];
  const activityStatus = [
    {
      key: 1,
      value: "报名未开始",
    },
    {
      key: 2,
      value: "报名进行中",
    },
    {
      key: 3,
      value: "活动未开始",
    },
    {
      key: 4,
      value: "活动进行中",
    },
    {
      key: 5,
      value: "已结束",
    },
    {
      key: 6,
      value: "活动取消",
    },
  ];
  const relationList = [
    {
      key: 1,
      value: "本人",
    },
    {
      key: 2,
      value: "子女",
    },
    {
      key: 3,
      value: "父母",
    },
    {
      key: 4,
      value: "配偶",
    },
    {
      key: 5,
      value: "（外）祖父母",
    },
    {
      key: 6,
      value: "（外）孙子女",
    },
    {
      key: 7,
      value: "其他",
    },
  ];
  const auditStatusOps = [
    {
      key: 0,
      value: "待审核",
    },
    {
      key: 1,
      value: "审核通过",
    },
    {
      key: 2,
      value: "审核驳回",
    },
  ];
  export { peopleRange, activityStatus, relationList, auditStatusOps };
  