<template>
  <div class="common">
    <div class="common-type">
      <div class="common-title">
        <div class="back" @click="$router.back()">
          <img src="./img/back.png" alt="" />
        </div>
        <span>{{ title }}</span>
        <div class="operate">
          <slot name="operate-btn"></slot>
        </div>
      </div>
      <div class="content">
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "common",
  components: {},
  props: {
    title: {
      type: String,
      default: "",
    },
  },
  data() {
    return {};
  },
  created() {},
  mounted() {},
  methods: {},
};
</script>

<style scoped lang="less">
.common {
  height: 100%;
  width: 100%;
  padding: 39px 24px 55px 39px;
  box-sizing: border-box;
  .common-type {
    height: 100%;
    display: flex;
    flex-direction: column;
    background: #ffffff;
    border-radius: 15px;
    padding: 49px 42px 0 41px;
    box-sizing: border-box;
    .content {
      flex: 1;
      width: 100%;
      height: calc(100% - 50px);
      box-sizing: border-box;
    }
    .common-title {
      box-sizing: border-box;
      position: relative;
      text-align: center;
      display: flex;
      flex-shrink: 0;
      span {
        flex: 1;
        text-align: center;
        font-weight: 600;
        font-size: 24px;
        color: #1874fd;
        line-height: 33px;
        font-style: normal;
      }
      .back {
        width: 40px;
        height: 40px;
        position: relative;
        z-index: 999;
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}
</style>
