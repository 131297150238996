<template>
  <common title="我的活动">
    <div class="joinActivity">
      <div class="activity-list">
        <div
          class="list"
          v-for="(item, index) in list"
          :key="index"
          @click="handelClickDetail(item)"
        >
          <!-- <div class="status">
            <div
              :class="[
                'status-text',
                {
                  finish: item.activityStatus == 5,
                },
              ]"
            >
              {{ getOption(item.activityStatus, item.hasFeedback, item) }}
            </div>
          </div> -->
          <div class="item-l">
            <img :src="item.activityPicture" alt="" />
          </div>
          <div class="item-r">
            <p class="name">{{ item.activityName }}</p>
            <div class="active-type">
              <div class="status-red" v-if="item.activityCategory == 1">
                热门活动
              </div>
              <div class="status-blue" v-else-if="item.activityCategory == 20">
                志愿活动
              </div>
              <div class="status-blue" v-else-if="item.activityCategory == 19">
                幸福学堂
              </div>
            </div>
            <div class="active-address">
              活动地址：{{ item.activityAddress }}
            </div>
            <p class="p-apply">报名开始时间：{{ item.applyStartDate }}</p>
            <p class="p-activity">活动开始时间：{{ item.startDate }}</p>
          </div>
        </div>
      </div>
      <div class="pagination">
        <van-pagination
          force-ellipses
          v-model="searchParams.curPage"
          :total-items="totals"
          :items-per-page="searchParams.pageSize"
          @change="handelChangePagination"
        >
          <template #prev-text>
            <van-icon name="arrow-left" />
          </template>
          <template #next-text>
            <van-icon name="arrow" />
          </template>
          <template #page="{ text }">{{ text }}</template>
        </van-pagination>
      </div>
    </div>
  </common>
</template>

<script>
import { getOption } from "@/utils/utils.js";
import Vue from "vue";
import { Pagination, Icon } from "vant";
Vue.use(Pagination);
Vue.use(Icon);
import { getApplyActivityList } from "./api";
import { activityStatus } from "./map";
import { mapState } from "vuex";
import common from "../components/common.vue";
export default {
  name: "myActivity",
  components: {
    common,
  },
  props: {},
  data() {
    return {
      activityStatus,
      totals: 0, //数据总页数
      list: [], //活动数据
      searchParams: {
        curPage: 1,
        pageSize: 6,
      },
    };
  },
  created() {},
  mounted() {
    this.getActivityList();
  },
  computed: {
    ...mapState(["userId", "tenantId", "communityId", "houseId"]),
  },
  methods: {
    //获取活动列表
    getActivityList() {
      const params = {
        ...this.searchParams,
        userId: this.userId,
        // userId: "1763412296209608705",
      };
      this.$axios
        .get(getApplyActivityList, {
          params,
        })
        .then((res) => {
          if (res.code == 200) {
            const { records, total } = res.data;
            this.totals = total;
            this.list = records;
          }
        });
    },

    //
    getOption(status, hasFeedback, item) {
      if (hasFeedback && hasFeedback == 1) {
        return "活动回顾";
      }
      if (item.applyUserCount >= item.limitCount && item.limitCount != 0) {
        return "报名人数已满";
      }
      if (getOption(status, activityStatus, "key")["value"]) {
        return getOption(status, activityStatus, "key")["value"];
      } else {
        return "";
      }
    },
    //切页
    handelChangePagination(value) {
      this.searchParams.curPage = value;
      this.getActivityList();
    },
    //跳转详情页面
    handelClickDetail(item) {
      this.$router.push({
        name: "myActivityDetail",
        query: {
          activityId: item.activityId,
          activityName: item.activityName,
          applyId: item.applyId,
        },
      });
    },
  },
};
</script>

<style scoped lang="less">
.joinActivity {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 37px 0 26px 0;
  box-sizing: border-box;
  .activity-list {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    // justify-content: space-between;
    .list {
      display: flex;
      width: calc((100% - 40px) / 3);
      margin-bottom: 15px;
      height: 174px;
      background: #f6f7fa;
      border-radius: 4px;
      box-sizing: border-box;
      border: 1px solid #e4e7ed;
      // margin-right: 10px;
      position: relative;
      &:not(:nth-child(3n)) {
        margin-right: 20px;
      }
      .status {
        position: absolute;
        right: 0;
        top: 0;
        .status-text {
          padding: 4px 10px;
          background: #1874fd;
          border-radius: 0px 4px 0px 100px;
          font-family: PingFangSC, PingFang SC;
          font-weight: 400;
          font-size: 12px;
          color: #ffffff;
          line-height: 17px;
          text-align: center;
          font-style: normal;
          &.finish {
            color: #a7a7a7;
            background: #e6e6e6;
          }
        }
      }
      .item-l {
        flex-shrink: 0;
        width: 142px;
        height: 100%;
        padding: 12px 20px 13px 11px;
        box-sizing: border-box;
        img {
          width: 100%;
          height: 100%;
          border-radius: 4px;
          object-fit: cover;
        }
      }
      .item-r {
        width: calc(100% - 142px);
        z-index: 2;
        padding-top: 22px;
        padding-right: 25px;
        box-sizing: border-box;
        .name {
          width: 100%;
          white-space: nowrap;
          overflow: hidden;
          box-sizing: border-box;
          text-overflow: ellipsis;
          font-family: PingFangSC, PingFang SC;
          font-weight: 500;
          font-size: 18px;
          color: #2f3a4f;
          line-height: 25px;
          text-align: left;
          font-style: normal;
        }
        .active-type {
          margin: 9px 0 8px 0;
          box-sizing: border-box;
          .status-red {
            width: 60px;
            height: 19px;
            border-radius: 4px;
            border: 1px solid #ed0a10;
            font-weight: 400;
            font-size: 12px;
            color: #ed0a10;
            line-height: 19px;
            text-align: center;
          }
          .status-blue {
            width: 60px;
            height: 19px;
            border-radius: 4px;
            border: 1px solid #2a7271;
            font-weight: 400;
            font-size: 12px;
            color: #2a7271;
            text-align: center;
            line-height: 19px;
          }
        }
        .active-address {
          width: 100%;
          margin-bottom: 7px;
          white-space: nowrap;
          overflow: hidden;
          box-sizing: border-box;
          text-overflow: ellipsis;
          font-weight: 400;
          font-size: 14px;
          color: #666666;
          line-height: 20px;
          text-align: left;
        }
        .p-apply,
        .p-activity {
          font-weight: 400;
          font-size: 14px;
          color: #666666;
          line-height: 20px;
          position: relative;
          padding-left: 12px;
          &::before {
            content: "";
            position: absolute;
            width: 6px;
            height: 6px;
            background: linear-gradient(180deg, #3397fe 0%, #3666ef 100%);
            border-radius: 50%;
            left: 0;
            top: 0;
            bottom: 0;
            margin: auto;
          }
        }
        .p-apply {
          margin-bottom: 8px;
          position: relative;
          &::before {
            content: "";
            position: absolute;
            width: 6px;
            height: 6px;
            background: #1ebb70;
            border-radius: 50%;
            left: 0;
            top: 0;
            bottom: 0;
            margin: auto;
          }
        }
      }
    }
  }
  .pagination {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    ::v-deep .van-pagination {
      .van-pagination__item {
        min-width: 32px;
        height: 32px;
        border-radius: 8px;
        &:active {
        }
        &.van-pagination__item--disabled {
          background-color: transparent;
        }
        // color: rgba(0, 0, 0, 0.85);
        &.van-pagination__item--active {
          // color: #fff;
        }
        &::after {
          border-width: 0;
        }
      }
    }
  }
}
</style>
