
// 已报名的活动列表
const getApplyActivityList = `/gateway/hc-serve/miniapi/activity/myActivityList`;
//获取活动详情
const getActivityDetail = `/gateway/hc-serve/miniapi/activity/activityDetail`;
//取消报名
const cancelApply = `/gateway/hc-serve/miniapi/activity/cancelActivityApply`
export {
    getApplyActivityList,
    getActivityDetail,
    cancelApply
  };